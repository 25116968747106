.container {
  display: flex;
}

.search-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .search-input {
    width: 300px;
  }
}
