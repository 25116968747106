.tool-box {
  margin-bottom: 30px;
}
.staking-box {
  display: flex;
  flex-wrap: wrap;
}
.card-container {
  width: 45%;
  min-width: 400px;
  max-width: 600px;
  margin-bottom: 10px;
  margin-right: 20px;
  padding: 30px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-container-single {
  width: 100%;
  min-width: 400px;
  max-width: 600px;
  padding: 30px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.text-row {
  display: flex;
  margin-bottom: 10px;
}
.text-label {
  font-weight: bold;
  width: 200px;
  text-align: right;
  margin-right: 20px;
}
.text-value {
  word-break: break-all;
}
.text-tool-box {
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 800px) {
  .card-container {
    width: 100%;
    min-width: auto;
    max-width: auto;
    .text-row {
      flex-direction: column;
      .text-label {
        text-align: left;
      }
    }
  }
}

.wallet-box {
  word-break: break-all;
  text-align: center;
  font-weight: bold;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
}
