.main {
  height: 100%;
  background-position: center;
  background-size: cover;
}

.title-box {
  text-align: center;
  margin: 10px 0 30px 0;
}

.login-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  width: 500px;
  background-color: rgba(255, 255, 255, 0.8);
  border: 5px solid;
  padding: 30px;
  box-shadow: 0 10px 10px #999;
  border-image: linear-gradient(
      0deg,
      rgba(0, 114, 206, 1),
      rgba(229, 64, 255, 1)
    )
    1;
  animation-duration: 5s;
  animation-name: login-box-animation;
  animation-iteration-count: infinite;
}

.input-btn-row {
  display: flex;
}

.submit-btn-box {
}

.footer-box {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@keyframes login-box-animation {
  from {
    border-image: linear-gradient(
        0deg,
        rgba(0, 114, 206, 1),
        rgba(229, 64, 255, 1)
      )
      1;
  }
  25% {
    border-image: linear-gradient(
        90deg,
        rgba(0, 114, 206, 1),
        rgba(229, 64, 255, 1)
      )
      1;
  }
  50% {
    border-image: linear-gradient(
        180deg,
        rgba(0, 114, 206, 1),
        rgba(229, 64, 255, 1)
      )
      1;
  }
  75% {
    border-image: linear-gradient(
        270deg,
        rgba(0, 114, 206, 1),
        rgba(229, 64, 255, 1)
      )
      1;
  }
  to {
    border-image: linear-gradient(
        360deg,
        rgba(0, 114, 206, 1),
        rgba(229, 64, 255, 1)
      )
      1;
  }
}

@media screen and (max-width: 1200px) {
  .main {
    width: 100%;
    overflow: hidden;
  }
  .login-box {
    width: 100%;
    overflow: hidden;
    top: 10%;
    transform: translate(-50%, 0%);
  }
}
