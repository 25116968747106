.slider-box {
  width: 100%;
  :global(.ant-slider) {
    height: 20px;
  }
  :global(.ant-slider-track) {
    height: 20px;
  }
  :global(.ant-slider-rail) {
    height: 20px;
  }
  :global(.ant-slider-handle) {
    height: 20px;
    width: 20px;
    margin-top: 0;
  }
  :global(.ant-slider-dot) {
    height: 15px;
    width: 15px;
    top: 2px;
  }
  :global(.ant-slider-mark) {
    top: 25px;
  }
}

.img-box {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    transition: all 0.3s ease;
  }
}

.staking-info-container {
  display: flex;
  justify-content: space-between;
  height: 100px;
  .staking-info-box {
    width: 60%;
  }
  .staking-credit-diff {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 60px;
    font-size: 25px;
    width: 150px;
    color: orange;
  }
}

.score-data-container {
  padding-top: 10px;
  .info-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  .info-value {
    color: goldenrod;
    font-size: 20px;
  }
}
