.container {
  height: 100%;
  background-position: center;
  background-size: cover;
}

.search-box {
  width: 50%;
  margin: 0 auto;
  margin-bottom: 50px;
}

.staking-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-box {
  position: relative;
}

.loading-box {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  background-color: rgba(255, 255, 255, 0.5);
}

@media screen and (max-width: 800px) {
  .search-box {
    width: 100%;
  }
}
