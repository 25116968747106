.header {
  padding: 0 30px;
  height: 60px;
  display: flex;
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.logo-box {
  width: 150px;
  img {
    width: 100%;
  }
}

.nav-box {
}
