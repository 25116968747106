.container {
  display: flex;
  flex-wrap: wrap;
}

.info-container {
  margin-right: 50px;
  background-color: #fff;
  padding: 30px;
  border: 1px solid #ddd;
  min-width: 500px;
}

.info-h3 {
  text-align: center;
  font-size: 20px;
  margin-bottom: 30px;
}

.staking-container {
  width: 500px;
  background-color: #fff;
  padding: 30px;
  border: 1px solid #ddd;
  overflow: hidden;
  height: 500px;
}

.text-row {
  display: flex;
  margin-bottom: 10px;
}
.text-label {
  font-weight: bold;
  width: 200px;
  text-align: right;
  margin-right: 20px;
}
.text-value {
  word-break: break-all;
}
.text-tool-box {
  display: flex;
  justify-content: flex-end;
}

.slider-box {
  width: 100%;
  :global(.ant-slider) {
    height: 20px;
  }
  :global(.ant-slider-track) {
    height: 20px;
  }
  :global(.ant-slider-rail) {
    height: 20px;
  }
  :global(.ant-slider-handle) {
    height: 20px;
    width: 20px;
    margin-top: 0;
  }
  :global(.ant-slider-dot) {
    height: 15px;
    width: 15px;
    top: 2px;
  }
  :global(.ant-slider-mark) {
    top: 25px;
  }
}

.img-box {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    transition: all 0.3s ease;
  }
}

.staking-info-container {
  display: flex;
  justify-content: space-between;
  height: 100px;
  .staking-info-box {
    width: 60%;
  }
  .staking-credit-diff {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 60px;
    font-size: 25px;
    width: 150px;
    color: orange;
  }
}

.score-data-container {
  padding-top: 10px;
  .info-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  .info-value {
    color: goldenrod;
    font-size: 20px;
  }
}

.add-stake-btn-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 50px;
}

.max-credit-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 300px;
  font-size: 30px;
  color: goldenrod;
}

.record-container {
  margin-top: 50px;
}

.staking-record-table {
  margin-top: 30px;
}

@media screen and (max-width: 800px) {
  .container {
    display: block;
  }
  .info-container {
    width: 100%;
    min-width: auto;
  }
  .text-row {
    flex-wrap: wrap;
    .text-label {
      text-align: left;
    }
  }
  .staking-container {
    margin-top: 30px;
    width: 100%;
  }
}
