.container {
  display: flex;
  flex-wrap: wrap;
}

.info-container {
  margin-right: 50px;
  background-color: #fff;
  padding: 30px;
  border: 1px solid #ddd;
  min-width: 500px;
}

.info-h3 {
  text-align: center;
  font-size: 20px;
  margin-bottom: 30px;
}

.text-row {
  display: flex;
  margin-bottom: 10px;
}
.text-label {
  font-weight: bold;
  width: 200px;
  text-align: right;
  margin-right: 20px;
}
.text-value {
  word-break: break-all;
}
.text-tool-box {
  display: flex;
  justify-content: flex-end;
}

.usdt-box {
  display: flex;
  justify-content: center;
  align-items: center;
  .usdt-value {
    font-size: 30px;
    margin-right: 30px;
    font-weight: bold;
    color: orange;
  }
  .usdt-label {
    font-weight: bold;
  }
}

.btn-box {
  margin-top: 30px;
}

.approve-btn {
  background-color: rgb(82, 196, 26);
  border-color: rgb(73, 190, 14);
  &:hover,
  &:focus {
    background-color: rgb(147, 223, 109);
  }
  &:active {
    background-color: rgb(88, 172, 46);
  }
}

@media screen and (max-width: 800px) {
  .container {
    display: block;
  }
  .info-container {
    width: 100%;
    min-width: auto;
  }
  .text-row {
    flex-wrap: wrap;
    .text-label {
      text-align: left;
    }
  }
}
