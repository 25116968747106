.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 20px;
}

.site-layout .site-layout-background {
  background: #fff;
}

.AdminContainer {
  height: 100%;
}
.mainContainer {
  overflow: auto;
  padding: 24px;
}
.locale-box {
  text-align: center;
}
.header-container {
  display: flex;
  justify-content: space-between;
  .logo-box {
    width: 120px;
    img {
      width: 100%;
    }
  }
}

.mobile-side {
  :global(.ant-menu) {
    position: absolute;
    padding-top: 60px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.user-box {
  position: absolute;
  width: 100%;
  bottom: 30px;
  padding: 10px 10px;
  background-color: #243c53;
  color: #ddd;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
